<template>
  <ClientOnly>
    <div
      v-click-outside="clickOutside"
      class="nav-main-avatar relative mr-2 cursor-pointer group"
    >
      <div
        ref="toggleIcon"
        class="relative w-10 h-10"
        @click="toggle"
      >
        <img
          class="rounded-full border-2 border-white transition-all group-hover:border-cyan-500 p-[1px] w-10 h-10 object-cover"
          :src="userData?.avatarUrl"
          alt="Your avatar"
          decoding="async"
          :class="{'border-white' : showToggle}"
        >
      </div>

      <transition name="fade-fast">
        <ul
          v-show="showToggle"
          class="flex flex-col absolute z-20 bg-slate-700 shadow-2xl rounded-xl rounded-t-none top-[52px] overflow-hidden -right-[84px] w-[250px] "
        >
          <li v-if="showCurrencySwitch" class="block whitespace-nowrap border-b border-slate-500">
            <div class="px-3 py-3">
              <SwitchCompetitionValue/>
            </div>
          </li>

          <li
            v-for="item in itemsDisplay"
            :key="item.label"
            class="block whitespace-nowrap border-b border-slate-500"
          >
            <button class="flex w-full items-center px-3 py-2.5 hover:bg-slate-600 hover:text-cyan-500 transition-all duration-100" @click="linkAction(item)">
              <span
                class="text-2xl mr-3"
                :class="item.icon"
              />
              <span class="text-lg">{{ item.label }}</span>
            </button>
          </li>

          <li class="block whitespace-nowrap">
            <button class="flex w-full items-center px-3 py-2.5 hover:bg-slate-600 hover:text-cyan-500 transition-all duration-100" @click="logout()">
              <span class="text-2xl icon-ico-logout mr-3"/>
              <span class="text-lg">Logout</span>
            </button>
          </li>
        </ul>
      </transition>
    </div>
  </ClientOnly>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

const props = defineProps({
  userData: {
    type: Object,
    default: null,
  },
  mainNavLinks: {
    type: Array,
    default: null,
  },
  showCurrencySwitch: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const {
  logout,
} = authStore;

const uiStore = useUiStore();
const {
  openTxModal,
} = uiStore;

const route = useRoute();

const showToggle = ref(false);
const config = useRuntimeConfig().public;

const itemsDisplay = computed(() => {
  const items = [
    {
      label: 'Winners',
      icon: 'icon-ico-winner-1',
      url: '/winners',
    },
    {
      label: 'Activity',
      icon: 'icon-ico-clock-solid',
      url: '/activity',
    },
    {
      label: 'My profile',
      icon: 'icon-ico-account',
      url: `/mw/${props.userData?.displayName?.toLowerCase()}`,
    },
    {
      label: 'Wallet',
      icon: 'icon-ico-wallet',
      url: '/wallet',
      modal: true,
      disabled: !config.WALLET_ACTIVE,
    },
    {
      label: 'Make It Rain',
      icon: 'icon-ico-thunder',
      url: '/account/tip',
      disabled: !props.userData?.tags?.includes('TipsEnabled'),
    },
    {
      label: 'Referrals',
      icon: 'icon-ico-user-add',
      url: '/referrals',
      disabled: !config.REFERRALS_ACTIVE,
    },
    {
      label: 'Settings',
      icon: 'icon-ico-settings',
      url: '/account',
    },
  ];

  if (props.mainNavLinks) {
    let mainNavLinks = props.mainNavLinks;
    mainNavLinks = mainNavLinks.filter(i => i.showInTooltip && !i.showDesktop);

    items.unshift(...mainNavLinks);
  }

  return items.filter(i => !i.disabled);
});

function toggle() {
  showToggle.value = !showToggle.value;
}

function clickOutside(e) {
  if (e?.target?.id === 'prizeValueCurrencySwitch') {
    return;
  }

  showToggle.value = false;
}

function linkAction(item) {
  if (item.modal && item.url === '/wallet') {
    return openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
  } else {
    return navigateTo(item?.url);
  }
}

watch(
  () => route,
  () => {
    showToggle.value = false;
  }
);
</script>
